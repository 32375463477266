import React, { useState } from "react";
import "./App.scss";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const photos = [
  { src: "/projects/antigravity.gif", width: 1414, height: 3081 },
  {
    src: "/projects/poster_v2_w_hollyshort_laurel.png",
    width: 1350,
    height: 2000,
  },

  { src: "/projects/nyx-email2.png", width: 800, height: 718 },
  { src: "/projects/bg_comp_v1.1.png", width: 1370, height: 2007 },
  { src: "/projects/Acquistion-version2.png", width: 1275, height: 1760 },
  { src: "/projects/chicago.png", width: 525, height: 221 },
  { src: "/projects/comp2.v2.png", width: 1190, height: 1793 },
  { src: "/projects/compv2-standard.png", width: 1000, height: 1690 },
  { src: "/projects/DSC04874.JPG", width: 720, height: 482 },
  { src: "/projects/e-a-l.png", width: 1280, height: 746 },
  { src: "/projects/entremet_tall_b-2.png", width: 960, height: 1679 },
  { src: "/projects/homepage.comp1.jpg", width: 1240, height: 1537 },
  // { src: "/projects/homepage.v1.png", width: 1414, height:3081},
  { src: "/projects/homepage-comp.v1.jpg", width: 1439, height: 4892 },

  { src: "/projects/pf-taylor-homepage.v1.png", width: 1580, height: 2971 },

  { src: "/projects/rally.png", width: 1275, height: 1650 },
  { src: "/projects/rtt-shirt.png", width: 1702, height: 1652 },
  { src: "/projects/goforit.png", width: 1687, height: 940 },
  { src: "/projects/havianas.png", width: 795, height: 556 },
  { src: "/projects/logo.v1.png", width: 436, height: 464 },
  { src: "/projects/bottles_mockup.png", width: 1024, height: 768 },
];

function App() {
  const [index, setIndex] = useState(-1);
  return (
    <div className="App">
      <header>
        <a href="mailto:info@tdanieldesign.com">
          <img
            src="./images/logo.png"
            alt="T. Daniel Print + Interactive Logo"
          />
        </a>
      </header>
      
      <section className="projects">
        <PhotoAlbum
          layout="masonry"
          photos={photos}
          columns={(containerWidth) => {
            if (containerWidth < 800) return 1;
            if (containerWidth < 1280) return 2;
            if (containerWidth > 1280) return 3;
            return 4;
          }}
          onClick={({ index: current }) => setIndex(current)}
        />
      </section>
      <section className="clients">
        <h2>Some Folks I've Worked With:</h2>
        <div className="clients-list">
          <img src="./clients/etrade.png" alt="ETRADE" />
          <img src="./clients/havaianas.png" alt="Havaianas" />
          <img src="./clients/khronos.png" alt="Khronos Group" />
          <img src="./clients/lens.png" alt="The Lens" />
          <img src="./clients/nyx.png" alt="NYX" />
          <img src="./clients/rcsh.png" alt="Ruth's Chris Steak House" />
          <img src="./clients/scalawag.png" alt="Scalawag" />
          <img src="./clients/tulane.png" alt="Tulane University" />
          <img src="./clients/wbae.png" alt="Waggoner & Ball" />
        </div>
      </section>
      {/* 
      <section>
        <h1>Services</h1>

        <h2>Design</h2>
        <ul>
          <li>Photoshop</li>
          <li>Illustrator</li>
          <li>InDesign</li>
          <li>After Effects</li>
          <li>Blender</li>
          <li>Substance Painter</li>
          <li>Substance Designer</li>
        </ul>

        <h2>Development</h2>
        <ul>
          <li>WordPress - Theme/Block/PLugin</li>
          <li>React</li>
          <li>NextJS</li>
          <li>Vue</li>
          <li>node</li>
        </ul>

        <h2>Languages</h2>
        <ul>
          <li>typescript</li>
          <li>php</li>
          <li>c#</li>
          <li>css/scss</li>
        </ul>

        <h2>Consulting</h2>
        <ul>
          <li>Content Architecture</li>
          <li>User Interface & Experience</li>
          <li>Branding</li>
          <li>SEO & Onpage Tracking</li>
        </ul>
      </section>
        */}
      <Lightbox
        index={index}
        slides={photos}
        open={index >= 0}
        close={() => setIndex(-1)}
      />
    </div>
  );
}

export default App;
